var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.categorysList.length > 0)?_c('div',{key:_vm.$route.name,staticClass:"container-carousel-categorys"},[_vm._m(0),_c('carousel',{key:_vm.innerWidth,staticClass:"col-12 mx-0 position-relative carousel-products-content",attrs:{"id":"carousel-banner-category","dots":true,"lazy-load":true,"nav":false,"responsive":{
      0: { items: 1, nav: false, stagePadding: 60 },
      704: { items: 3, nav: false, stagePadding: 60 },
      1030: { items: 4, nav: false, stagePadding: 50 },
      1200: { items: 5, nav: false, stagePadding: 30 },
    }}},[_c('template',{slot:"prev"},[_c('span',{staticClass:"prev nav-button d-none d-md-block"},[_c('img',{attrs:{"src":require("@/assets/icons/left-carousel.svg"),"alt":"left arrow carousel"}})])]),_c('template',{slot:"next"},[_c('span',{staticClass:"next nav-button d-none d-md-block"},[_c('img',{attrs:{"src":require("@/assets/icons/right-carousel.svg"),"alt":"right arrow carousel"}})])]),_vm._l((_vm.categorysList),function(category,index){return _c('div',{key:index,staticClass:"item-category-home"},[_c('router-link',{attrs:{"to":{
          name: 'TiendaSubcategory',
          params: {
            category: category.departamento,
            subcategory: category.tipoprenda_slug,
            nameCreative: `home-category-${category.tipoprenda_slug}`,
            slot: `Home-primary-categories`,
            id: `${category.tipoprenda_slug_singular}`,
            name: `${category.tipoprenda_texto}`,
          },
        }}},[_c('div',{staticClass:"container-img-carousel-category"},[_c('img',{attrs:{"src":category.img,"alt":""}})]),_c('div',{staticClass:"item-category"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(category.tipoprenda_descripcion .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")]),(category.destacado == 1)?_c('img',{staticClass:"novelity",attrs:{"src":require("@/assets/icons/novelty-icon.svg"),"alt":category.tipoprenda_slug}}):_vm._e()])])],1)})],2)],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ps-4"},[_c('h2',{staticClass:"title-carousel"},[_vm._v("Explora nuestras categorías /")])])
}]

export { render, staticRenderFns }