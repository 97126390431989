<template>
  <div
    v-if="categorysList.length > 0"
    :key="$route.name"
    class="container-carousel-categorys"
  >
    <div class="ps-4">
      <h2 class="title-carousel">Explora nuestras categorías /</h2>
    </div>
    <carousel
      id="carousel-banner-category"
      :key="innerWidth"
      class="col-12 mx-0 position-relative carousel-products-content"
      :dots="true"
      :lazy-load="true"
      :nav="false"
      :responsive="{
        0: { items: 1, nav: false, stagePadding: 60 },
        704: { items: 3, nav: false, stagePadding: 60 },
        1030: { items: 4, nav: false, stagePadding: 50 },
        1200: { items: 5, nav: false, stagePadding: 30 },
      }"
    >
      <template slot="prev"
        ><span class="prev nav-button d-none d-md-block">
          <img
            src="@/assets/icons/left-carousel.svg"
            alt="left arrow carousel"
          /> </span
      ></template>
      <template slot="next"
        ><span class="next nav-button d-none d-md-block">
          <img
            src="@/assets/icons/right-carousel.svg"
            alt="right arrow carousel"
          /> </span
      ></template>
      <div
        v-for="(category, index) in categorysList"
        :key="index"
        class="item-category-home"
      >
        <router-link
          :to="{
            name: 'TiendaSubcategory',
            params: {
              category: category.departamento,
              subcategory: category.tipoprenda_slug,
              nameCreative: `home-category-${category.tipoprenda_slug}`,
              slot: `Home-primary-categories`,
              id: `${category.tipoprenda_slug_singular}`,
              name: `${category.tipoprenda_texto}`,
            },
          }"
        >
          <div class="container-img-carousel-category">
            <img :src="category.img" alt="" />
          </div>
          <div class="item-category">
            <p class="mb-0">
              {{
                category.tipoprenda_descripcion
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}
            </p>
            <img
              v-if="category.destacado == 1"
              class="novelity"
              src="@/assets/icons/novelty-icon.svg"
              :alt="category.tipoprenda_slug"
            />
          </div>
        </router-link>
      </div>
    </carousel>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import carousel from 'v-owl-carousel'
export default {
  components: { carousel },
  computed: {
    ...mapState('layouts', ['categorysList']),
    ...mapState(['innerWidth']),
  },
  watch: {
    innerWidth() {
      this.validateDotsCarousel()
    },
  },
  async mounted() {
    if (!this.categorysList) {
      await this.getCategorys()
    }
  },
  methods: {
    ...mapActions('layouts', ['getCategorys']),
    validateDotsCarousel() {
      if (this.products) {
        setTimeout(() => {
          let element = document
            .getElementById('carousel-banner-category')
            .getElementsByClassName('owl-dot')
          element.forEach(e => {
            e.ariaLabel = 'Dot carousel'
            e.style.width =
              100 /
                (this.innerWidth >= 1048
                  ? parseInt(this.products.length / 2)
                  : this.products.length) +
              '%'
          })
        }, 100)
      }
    },
  },
}
</script>
