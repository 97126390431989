<template>
  <div class="page-404-container container-max-width">
    <div class="container-404">
      <div class="text-center">
        <img class="w-100 px-4 px-md-0" src="@/assets/icons/page404.svg" />
        <h1 style="font-size: 50px; font-weight: bold">
          Parece que esta página no existe
        </h1>
        <p class="fs-6">
          La página que intentas buscar no existe, intenta realizar una nueva
          <br class="d-none d-md-flex" />
          búsqueda o navega entre nuestras categorías.
        </p>
      </div>
    </div>
    <nav-category :key="showcarouselWp" class="container-max-width" />
  </div>
</template>
<script>
import NavCategory from '@/components/Home/NavCategory.vue'
export default {
  components: { NavCategory },
  data() {
    return {
      showcarouselWp: 1,
    }
  },
  async mounted() {
    await setTimeout(() => {
      this.showcarouselWp += 1
    }, 700)
  },
}
</script>
